var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('my-nav-bar', {
    attrs: {
      "title": "联盟提货",
      "left-text": "返回",
      "left-arrow": ""
    }
  }), _c('van-list', {
    staticClass: "list safe-area-padding-bottom",
    attrs: {
      "finished": _vm.finished
    },
    on: {
      "load": _vm.getList
    },
    model: {
      value: _vm.loading,
      callback: function callback($$v) {
        _vm.loading = $$v;
      },
      expression: "loading"
    }
  }, [_vm.showEmpty ? _c('van-empty', {
    attrs: {
      "description": "暂无数据"
    }
  }) : _vm._e(), _vm._l(_vm.list, function (business) {
    return _c('van-cell', {
      key: business.id,
      attrs: {
        "title-style": "flex: 3",
        "title": business.name,
        "label": "\u8054\u7CFB\u7535\u8BDD\uFF1A".concat(business.phone, "\uFF0C\u5730\u5740\uFF1A").concat(business.address),
        "is-link": "",
        "center": ""
      },
      on: {
        "click": function click($event) {
          return _vm.toOrderView(business);
        }
      }
    }, [_vm._v(" " + _vm._s(business.order_count) + "张订单 ")]);
  })], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }