<template>
  <div>
    <my-nav-bar
      title="联盟提货"
      left-text="返回"
      left-arrow
    />
    <van-list
      v-model="loading"
      class="list safe-area-padding-bottom"
      :finished="finished"
      @load="getList"
    >
      <van-empty v-if="showEmpty" description="暂无数据" />
      <van-cell
        v-for="business in list"
        :key="business.id"
        title-style="flex: 3"
        :title="business.name"
        :label="`联系电话：${business.phone}，地址：${business.address}`"
        is-link
        center
        @click="toOrderView(business)"
      >
        {{ business.order_count }}张订单
      </van-cell>
    </van-list>
  </div>
</template>

<script>
import myNavBar from '@/components/my-nav-bar'
import { getBusinesses } from '@/api/business-take'

export default {
  name: 'BusinessTake',
  components: { myNavBar },
  filters: {
    statusFilter(status) {
      return status === 1 ? '正常营业' : '暂停营业'
    }
  },
  data() {
    return {
      listQuery: {
        page: 0
      },
      list: [],
      showEmpty: false,
      loading: false,
      finished: false
    }
  },
  methods: {
    getList() {
      this.listQuery.page++
      getBusinesses(this.listQuery).then(res => {
        this.list = this.list.concat(res.data.list)
        this.showEmpty = this.list.length === 0
        this.loading = false
        this.finished = res.data.list.length < 10
      })
    },
    toOrderView(business) {
      this.$router.push({
        path: 'business-take-order',
        query: {
          businessId: business.id,
          businessName: business.name
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .list {
    margin-top: 46px;
  }
</style>
